import { useEffect } from 'react';

import { breadcrumbsSlice } from 'domain/breadcrumbs/breadcrumbsSlice';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'hooks/redux';
import { useLocation } from 'react-router-dom';
import { CreatePayment } from '../components/create-payment/CreatePayment';

export const Container = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(
      breadcrumbsSlice.actions.setBreadcrumbs([
        {
          label: 'Virtual Terminal Payment',
          route: pathname,
        },
      ])
    );
  }, [dispatch, pathname, t]);

  return <CreatePayment />;
};
