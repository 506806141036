import { useTranslation } from 'react-i18next';
import React, { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { FilterSelect } from 'components/filters/FilterSelect';
import { useAppDispatch, useAppSelector } from 'hooks/redux';

import {
  defaultFilters,
  FiltersParams,
  notificationCategoryItems,
  notificationSeverityItems,
  notificationStatusItems,
  notificationTypeItems,
} from '../../utils';
import { getAppliedFilters, getFilters } from '../../selectors';
import {
  resetAllFilters,
  setApplyFilters,
  setFilters,
} from '../../notificationSlice';
import {
  DatePickerControl,
  DatePickerType,
  setDateFilter,
} from 'components/DatePickerControl';
import { FilterObjectWrapper } from 'components/filters/FilterObjectWrapper';
import { useFilterEffect } from 'utils/useFilterEffect';

type FiltersProps = {
  toggleDrawer?: () => void;
};

export const Filters: React.FC<FiltersProps> = ({ toggleDrawer }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const filters: FiltersParams = useAppSelector(getFilters);
  const appliedFilters: FiltersParams = useAppSelector(getAppliedFilters);

  const { control, handleSubmit, reset, watch, setValue } =
    useForm<FiltersParams>({
      defaultValues: appliedFilters,
    });

  const allValues = watch();
  const previousValues = useRef<FiltersParams>(appliedFilters);

  useFilterEffect(previousValues, allValues, setFilters);

  const resetFilter = () => {
    dispatch(resetAllFilters());
    reset(defaultFilters);
  };

  const handleApplyFilters = (data: FiltersParams) => {
    if (data.createdOn?.from && !data.createdOn?.to) {
      const updatedTransactionDate = {
        ...data.createdOn,
        to: data.createdOn?.from,
      };
      setValue('createdOn', updatedTransactionDate);
    }
    dispatch(setApplyFilters({}));
    if (toggleDrawer) toggleDrawer();
  };

  const handleDateChange = (callback: any, dates: any) => {
    const [start, end] = dates;
    if (start) {
      setDateFilter(callback, {
        from: start,
        to: null,
      });
    }

    if (start && end) {
      setDateFilter(callback, {
        from: start,
        to: end,
      });
    }
  };

  const filterComponentsList = {
    0: (
      <FilterSelect
        label={t('notificationManagement.filters.severity')}
        name="severity"
        control={control}
        options={notificationSeverityItems}
        isClearable={false}
      />
    ),
    1: (
      <FilterSelect
        label={t('notificationManagement.filters.type')}
        name="type"
        control={control}
        options={notificationTypeItems}
        isClearable={false}
      />
    ),
    2: (
      <FilterSelect
        label={t('notificationManagement.filters.status')}
        name="status"
        control={control}
        options={notificationStatusItems}
        isClearable={false}
      />
    ),
    3: (
      <FilterSelect
        label={t('notificationManagement.filters.category')}
        name="category"
        control={control}
        options={notificationCategoryItems}
        isClearable={false}
      />
    ),
    4: (
      <DatePickerControl
        control={control}
        label={t('notificationManagement.filters.createdOn')}
        handleChange={handleDateChange}
        datePickerType={DatePickerType.STANDARD_DATE_SELECT_ONE_MONTH}
        popoverWidth={260}
        placementPosition="bottom-end"
        name="createdOn"
        maxDate={new Date()}
        title={t('notificationManagement.filters.createdOn')}
      />
    ),
  };

  const notificationsHasFilter = [
    filters.severity?.length ||
      filters.status?.length ||
      filters.type?.length ||
      filters.category?.length ||
      filters.createdOn?.from,
  ].some((value) => !!value);

  return (
    <FilterObjectWrapper
      filterFields={filterComponentsList}
      onApply={handleSubmit(handleApplyFilters)}
      onClear={resetFilter}
      isDisabledClear={!notificationsHasFilter}
      isDisabledApply={!notificationsHasFilter}
    />
  );
};
