import { createAsyncThunk } from '@reduxjs/toolkit';

import { virtualTerminalResource } from './api';

export const createPayment: any = createAsyncThunk(
  'virtualTerminal/createPayment',
  async (data) => {
    try {
      return await virtualTerminalResource.createPayment(data);
    } catch (e: any) {
      return Promise.reject(e.error);
    }
  }
);

export const findCardBrand: any = createAsyncThunk(
  'virtualTerminal/findCardBrand',
  async (data) => {
    try {
      return await virtualTerminalResource.getCardBrand(data);
    } catch (e: any) {
      return Promise.reject(e);
    }
  }
);
