import {
  CardBrand,
  TransactionStatus,
  TransactionType,
} from '../../../entities';
import { DateRange } from '../../../types/dateRange';
import { ItemAttributes } from 'components/itemAttributeType';

// eslint-disable-next-line @typescript-eslint/ban-types
export type ItemFilters = {};

export type MiscFilters = {
  transactionDate: DateRange | null;
  cardLast4: string | null;
  authorizationCode: string | null;
  transactionAmount: number | null;
  transactionStatus: ItemAttributes[];
  transactionType: ItemAttributes[];
  merchants: ItemAttributes[];
  cardBrand: ItemAttributes[];
};

export type Filters = ItemFilters & MiscFilters;

const itemFilters: ItemFilters = {};

export const miscFilters: MiscFilters = {
  transactionDate: null,
  cardLast4: null,
  authorizationCode: null,
  transactionAmount: null,
  transactionStatus: [],
  transactionType: [],
  merchants: [],
  cardBrand: [],
};

export const defaultFilters: Filters = {
  ...itemFilters,
  ...miscFilters,
};

export const listRequestPayload = (
  options: Filters,
  updateTime: (value: any) => string | undefined
) => ({
  filters: {
    transactionDate: options.transactionDate?.from
      ? {
          from: updateTime(options.transactionDate.from),
          to: updateTime(
            options.transactionDate?.to
              ? options.transactionDate.to
              : options.transactionDate.from
          ),
        }
      : null,
    cardLast4: options.cardLast4,
    authorizationCode: options.authorizationCode,
    transactionAmount: options.transactionAmount,
    transactionStatus: options.transactionStatus?.map((value) => value.value),
    transactionType: options.transactionType?.map((value) => value.value),
    merchants: options.merchants?.map((value) => value.value),
    cardBrand: options.cardBrand?.map((value) => value.value),
  },
});

interface MappedDataProps {
  cardNumber: string;
  id: string;
  matchType: string;
  merchantName: string;
  transactionAmount: number;
  transactionDate: string;
  transactionTime: string;
  transactionStatus: TransactionStatus;
  transactionType: TransactionType;
  currencyCode: string;
  cardBrand: string;
  authCode: string;
  messageType: string;
}

export const mappedData = (data: any) => {
  return data.map((item: MappedDataProps) => ({
    id: item.id,
    cardNumber: item.cardNumber,
    transactionDate: item.transactionDate,
    transactionTime: item.transactionTime,
    transactionAmount: item.transactionAmount,
    transactionStatus: item.transactionStatus,
    transactionType: item.transactionType,
    cardBrand: item.cardBrand,
    merchantName: item.merchantName,
    matchType: item.matchType,
    authCode: item.authCode,
    currencyCode: item.currencyCode,
    messageType: item.messageType,
  }));
};

export enum TransactionStatuses {
  PROCESSED = 'PROCESSED',
  REJECTED = 'REJECTED',
  PROCESSED_A = 'A',
  REJECTED_R = 'R',
}

export const cardBrands: ItemAttributes[] = [
  { label: CardBrand.AMEX, value: CardBrand.AMEX },
  { label: CardBrand.DINERS, value: CardBrand.DINERS },
  { label: CardBrand.DISCOVER, value: CardBrand.DISCOVER },
  { label: CardBrand.JCB, value: CardBrand.JCB },
  { label: CardBrand.MASTER_CARD, value: CardBrand.MASTER_CARD },
  { label: CardBrand.VISA, value: CardBrand.VISA },
];
