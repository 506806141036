import {
  MerchantsItem,
  ReportsFilterProp,
  SubReportItems,
  SubReportTypes,
} from 'entities/reports';
import { ItemAttributes } from 'components/itemAttributeType';

export const defaultFilters: ReportsFilterProp = {
  merchants: [] as ItemAttributes[],
  status: [] as ItemAttributes[],
  date: {
    from: null,
    to: null,
  },
};

interface ReportDetailsResponseProps {
  id: string;
  title: string;
  type: string;
  merchants: MerchantsItem[];
  subReports: SubReportItems[];
  dateRange: string;
  generatedBy?: {
    firstName: string;
    id: string;
    lastName: string;
  };
  generatedAt: string;
  reviewedBy?: {
    firstName: string;
    id: string;
    lastName: string;
  };
  reviewedAt: string;
  status: string;
  reason: string;
  cause: string;
}

export const mappedAuthDetails = ({
  data,
}: {
  data: ReportDetailsResponseProps;
}) => {
  return {
    id: data.id,
    title: data.title,
    type: data.type,
    merchants: data.merchants,
    dateRange: data.dateRange,
    generatedBy:
      data.generatedBy !== null
        ? data.generatedBy?.firstName && data.generatedBy.lastName
          ? `${data.generatedBy.firstName} ${data.generatedBy.lastName}`
          : ''
        : 'FraudPrevention Generated',
    generatedAt: data.generatedAt,
    reviewedBy:
      data.reviewedBy?.firstName && data.reviewedBy?.lastName
        ? `${data.reviewedBy.firstName} ${data.reviewedBy.lastName}`
        : '',
    reviewedAt: data.reviewedAt,
    subReports: data.subReports,
    status: data.status,
    reason: data.reason,
    cause: data.cause,
  };
};

export const mappedData = (data: any) => {
  return data.map((item: any) => ({
    id: item.id,
    merchants: item.merchants,
    reportName: item.reportName,
    dateRange: item.dateRange,
    generatedBy:
      item.generatedBy !== null
        ? item.generatedBy?.firstName && item.generatedBy.lastName
          ? `${item.generatedBy.firstName} ${item.generatedBy.lastName}`
          : ''
        : 'FraudPrevention Generated',
    generatedAt: item.generatedAt,
    status: item.status,
  }));
};

export const initReportDetails = {
  id: '',
  title: '',
  type: '',
  merchants: [] as MerchantsItem[],
  subReports: [] as SubReportItems[],
  dateRange: '',
  generatedBy: '',
  generatedAt: '',
  reviewedBy: '',
  reviewedAt: '',
  status: '',
  reason: '',
  cause: '',
};

export const initSubReportTypeItems: ItemAttributes[] = [
  {
    label: 'All in one',
    value: SubReportTypes.COMPLETE_REPORT,
  },
  {
    label: 'All sub reports type',
    value: SubReportTypes.ALL_SUB_REPORTS_TYPE,
  },
];

export const reportStatusItems = [
  { label: 'ACCEPTED', value: 'ACCEPTED' },
  { label: 'REJECTED', value: 'REJECTED' },
  { label: 'PROCESSED', value: 'PROCESSED' },
  { label: 'PENDING', value: 'PENDING' },
  { label: 'FAILED', value: 'FAILED' },
];

export const listRequestPayload = (
  options: ReportsFilterProp,
  updateTime: (value: any) => string | undefined
) => ({
  status: options.status.map((m) => m.value) || undefined,
  merchants: options.merchants.map((m) => m.value) || undefined,
  date: options.date.from
    ? {
        from: updateTime(options.date.from),
        to: updateTime(
          options.date?.to ? options.date?.to : options.date?.from
        ),
      }
    : null,
});
