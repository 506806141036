import { Button } from '@mui/material';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { useAppDispatch } from 'hooks/redux';
import React from 'react';
import { useSelector } from 'react-redux';
import { getPaymentStep } from '../../selectors';
import {
  clearPaymentCreate,
  downPaymentCreateStepNumber,
} from '../../virtualTerminalPaymentSlice';
import { createParamsDefault } from '../../utils';

interface MerchantStepProps {
  isDisabled?: boolean;
  reset: any;
}

export const PaymentCreateSteps: React.FC<MerchantStepProps> = ({
  isDisabled = false,
  reset,
}) => {
  const dispatch = useAppDispatch();
  const paymentStep = useSelector(getPaymentStep);

  const cancelCreation = () => {
    reset({ ...createParamsDefault });
    dispatch(clearPaymentCreate());
    // history.push('/transaction-management/virtual-terminal');
  };

  return (
    <div className="grid justify-items-stretch grid-cols-2 gap-4 px-3 pb-6">
      <div className="justify-items-start">
        <Button
          color="info"
          disabled={paymentStep === 1}
          onClick={() => dispatch(downPaymentCreateStepNumber())}
          startIcon={<ChevronLeftOutlinedIcon />}
        >
          Back
        </Button>
      </div>
      <div className="flex gap-2 justify-self-end">
        <div>
          <Button
            color="error"
            onClick={() => cancelCreation()}
            startIcon={<CloseIcon />}
          >
            Clear
          </Button>
        </div>
        <div>
          {paymentStep !== 4 ? (
            <Button
              color="info"
              disabled={isDisabled}
              endIcon={<ChevronRightOutlinedIcon />}
              type="submit"
            >
              Next
            </Button>
          ) : (
            <Button color="primary" type="submit" variant="contained">
              Submit
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
