import { ItemAttributes } from 'components/itemAttributeType';

export interface CreateParams {
  register: any;
  control?: any;
  errors: any;
  watch?: any;
  handleSubmit?: any;
  setError?: any;
  setValue?: any;
  reset: any;
}

export interface SummeryCreateParams {
  watch?: any;
  reset: any;
  handleSubmit?: any;
}

enum PaymentType {
  SALES = 'Sales',
  PURCHASE = 'PURCHASE',
  AUTHORIZATION = 'Authorization',
  AUTHORIZATION_VALUE = 'AUTHORIZATION',
}

export const paymentTypeItems: ItemAttributes[] = [
  {
    label: PaymentType.SALES,
    value: PaymentType.PURCHASE,
  },
  {
    label: PaymentType.AUTHORIZATION,
    value: PaymentType.AUTHORIZATION_VALUE,
  },
];

export const createParamsDefault = {
  type: null,
  merchant: null,
  program: null,
  transactionDate: null,
  currency: { value: 'USD', label: 'US Dollar' },
  transactionAmount: 0.0,
  cardholderName: '',
  cardNumber: '',
  expirationDate: '',
  cVVcVC: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  country: { value: 'US', label: 'United States' },
  stateUS: null,
  state: '',
  postalZipCode: '',
  cardBrandCSS: 'rccs__card--unkn',
};
