import { ItemAttributes } from 'components/itemAttributeType';
import { NotificationType } from '../../dashboard/utils';

import { DateRange } from '../../../types/dateRange';
import moment from 'moment-timezone';
import { dateTimeSecondsFormat } from '../../../components/dateTimeFormatUtil';

export type FiltersParams = {
  createdOn: DateRange | null;
  status: ItemAttributes[] | null;
  type: ItemAttributes[] | null;
  severity: ItemAttributes[] | null;
  category: ItemAttributes[] | null;
};

export const defaultFilters: FiltersParams = {
  status: null,
  type: null,
  severity: null,
  category: null,
  createdOn: null,
};

export enum NotificationSeverityType {
  HIGH = 'High',
  MEDIUM = 'Medium',
  LOW = 'Low',
}

export enum NotificationCategoryType {
  MERCHANT_VOLUME_LIMITS = 'Merchant Volume Limits',
  DFM_FILE_MISSING = 'DFM File Missing',
  SERVICE_MONITORING = 'Service Monitoring',
}

export const listRequestPayload = (
  options: FiltersParams,
  dateZone: string
) => ({
  filters: {
    status: options.status?.map(({ value }) => value) || undefined,
    type: options.type?.map(({ value }) => value) || undefined,
    severity: options.severity?.map(({ value }) => value) || undefined,
    category: options.category?.map(({ value }) => value) || undefined,
    createdOn: options.createdOn?.from
      ? {
          from: moment(options.createdOn.from)
            .tz(dateZone)
            .startOf('day')
            .format(dateTimeSecondsFormat),
          to: moment(
            options.createdOn?.to
              ? options.createdOn.to
              : options.createdOn?.from
          )
            .tz(dateZone)
            .endOf('day')
            .format(dateTimeSecondsFormat),
        }
      : undefined,
  },
});

export const notificationSeverityItems: ItemAttributes[] = [
  {
    label: NotificationSeverityType.LOW,
    value: NotificationSeverityType.LOW,
  },
  {
    label: NotificationSeverityType.MEDIUM,
    value: NotificationSeverityType.MEDIUM,
  },
  {
    label: NotificationSeverityType.HIGH,
    value: NotificationSeverityType.HIGH,
  },
];

export const notificationTypeItems: ItemAttributes[] = [
  {
    label: NotificationType.INFORMATIONAL,
    value: NotificationType.INFORMATIONAL,
  },
  {
    label: NotificationType.ERROR,
    value: NotificationType.ERROR,
  },
  {
    label: NotificationType.ALERT,
    value: NotificationType.ALERT,
  },
  {
    label: NotificationType.WARNING,
    value: NotificationType.WARNING,
  },
  {
    label: NotificationType.SUCCESS,
    value: NotificationType.SUCCESS,
  },
  {
    label: NotificationType.REMINDER,
    value: NotificationType.REMINDER,
  },
  {
    label: NotificationType.SYSTEM_MAINTENANCE,
    value: NotificationType.SYSTEM_MAINTENANCE,
  },
  {
    label: NotificationType.SECURITY_ALERT,
    value: NotificationType.SECURITY_ALERT,
  },
  {
    label: NotificationType.ACTION_REQUIRED,
    value: NotificationType.ACTION_REQUIRED,
  },
  {
    label: NotificationType.PROMOTIONAL,
    value: NotificationType.PROMOTIONAL,
  },
];

export const notificationStatusItems: ItemAttributes[] = [
  { label: 'ACTIVE', value: 'ACTIVE' },
  { label: 'RESOLVED', value: 'RESOLVED' },
];

export enum NotificationCategoryEnum {
  MERCHANT_VOLUME_LIMITS = 'Merchant Volume Limits',
  DFM_FILE_MISSING = 'DFM File Missing',
  SERVICE_MONITORING = 'Service Monitoring',
}

export const notificationCategoryItems: ItemAttributes[] = [
  {
    label: NotificationCategoryEnum.MERCHANT_VOLUME_LIMITS,
    value: NotificationCategoryEnum.MERCHANT_VOLUME_LIMITS,
  },
  {
    label: NotificationCategoryEnum.DFM_FILE_MISSING,
    value: NotificationCategoryEnum.DFM_FILE_MISSING,
  },
  {
    label: NotificationCategoryEnum.SERVICE_MONITORING,
    value: NotificationCategoryEnum.SERVICE_MONITORING,
  },
];
