import moment from 'moment';
import Modal from 'react-modal';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { PrivateRoute } from 'components/PrivateRoute';
import { PublicRoute } from 'components/PublicRoute';
import { AuthContainer } from 'components/AuthContainer';
import { TransactionManagement } from 'domain/transaction-management/TransactionManagement';
import AppErrorBoundary from 'components/AppErrorBoundary';

import { Reports } from 'domain/reports/Reports';
import { System } from 'domain/system/System';
import { Logs } from 'domain/logs/Logs';
import { DisputeResolution } from 'domain/dispute-resolution/DisputeResolution';
import { UserManagement } from 'domain/user-management/UserManagement';
import { LayoutApp } from 'layouts/app/LayoutApp';
import { Container as LoginContainer } from 'domain/auth/login/Container';
import { Container as ForgotPassword } from 'domain/auth/forgot-password/Container';
import { Container as ChangePassword } from 'domain/auth/change-password/Container';
import { Container as ResetPassword } from 'domain/auth/reset-password/Container';
import { Container as LogoutContainer } from 'domain/auth/logout/Container';
import { Container as DashboardContainer } from 'domain/dashboard/Container';
import { Container as SettingsContainer } from 'domain/settings/Container';
import { Container as NotificationsContainer } from 'domain/notifications/notification/list/Container';
import { Container as VirtualPaymentContainer } from 'domain/virtual-terminal-payment/list/Container';
import { Container as ComplianceAuditContainer } from 'domain/compliance-audit/compliance-audit-component/Container';
import { ToastContainer } from 'react-toastify';
import { FraudPrevention } from './domain/fraud-prevention/FraudPrevention';
import { SecretKeyRout } from './domain/api-secret-key-management/SecretKeyRout';

Modal.setAppElement('#root');

export const AppRoutes = () => {
  require('moment-timezone');
  moment.tz.setDefault('UTC');
  return (
    <>
      <ToastContainer />
      <Router>
        <Switch>
          <PublicRoute path="/login">
            <LoginContainer />
          </PublicRoute>
          <PublicRoute path="/forgot-password/:token">
            <ResetPassword />
          </PublicRoute>
          <PublicRoute path="/create-password/:token">
            <ResetPassword />
          </PublicRoute>
          <PublicRoute path="/forgot-password">
            <ForgotPassword />
          </PublicRoute>
          <Route path="/change-password/:token">
            <ChangePassword />
          </Route>
          <Route path="/change-password">
            <ChangePassword />
          </Route>

          <Route path="/logout">
            <LogoutContainer />
          </Route>

          <PrivateRoute path="/">
            <AuthContainer>
              <LayoutApp>
                <div className="h-full">
                  <AppErrorBoundary>
                    <Switch>
                      <Route path="/" exact>
                        <DashboardContainer />
                      </Route>
                      <Route path="/settings">
                        <SettingsContainer />
                      </Route>
                      <Route path="/notifications">
                        <NotificationsContainer />
                      </Route>
                      <Route path="/compliance-audit">
                        <ComplianceAuditContainer />
                      </Route>
                      <Route path="/transaction-management">
                        <TransactionManagement />
                      </Route>
                      <Route path="/reports">
                        <Reports />
                      </Route>
                      <Route path="/fraud-prevention">
                        <FraudPrevention />
                      </Route>
                      <Route path="/system">
                        <System />
                      </Route>
                      <Route path="/virtual-terminal">
                        <VirtualPaymentContainer />
                      </Route>
                      <Route path="/monitoring">
                        <Logs />
                      </Route>
                      <Route path="/dispute-resolution">
                        <DisputeResolution />
                      </Route>
                      <Route path="/user-management">
                        <UserManagement />
                      </Route>
                      <Route path="/api-secret-key-management">
                        <SecretKeyRout />
                      </Route>
                    </Switch>
                  </AppErrorBoundary>
                </div>
              </LayoutApp>
            </AuthContainer>
          </PrivateRoute>
        </Switch>
      </Router>
    </>
  );
};
