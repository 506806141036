import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  getAppliedFilters,
  getFilters,
} from 'domain/transaction-management/authorizations/selectors';
import { FilterSelect } from 'components/filters/FilterSelect';
import { ItemAttributes } from 'components/itemAttributeType';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { Input, InputNumber } from 'components/inputs';
import { merchantList, merchantPortalList } from 'api/merchants';
import {
  defaultFilters,
  ListFilters,
  resetAllFilters,
  setApplyFilters,
  setFilters,
} from 'domain/transaction-management/authorizations/authorizationsSlice';

import { FilterMerchantExternalReference } from './FilterMerchantExternalReference';
import { FilterAuthorizationCode } from './FilterAuthorizationCode';
import { FilterNameOnCard } from './FilterNameOnCard';
import { authorizationStatusList } from '../../api';
import { getTransactionTypes } from './utils';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { isMerchantSelector } from 'domain/account/selectors';
import { useHistory } from 'react-router-dom';
import { RequiredFieldComponent } from 'components/RequiredFiledComponent';
import { FilterObjectWrapper } from 'components/filters/FilterObjectWrapper';
import { useFilterEffect } from 'utils/useFilterEffect';
import {
  DatePickerControl,
  DatePickerType,
  setDateFilter,
} from 'components/DatePickerControl';
import { countDecimalPlaces } from '../../utils';

type FiltersProps = {
  toggleDrawer?: () => void;
};

export const Filters: React.FC<FiltersProps> = ({ toggleDrawer }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history: any = useHistory();

  const appliedFilters: ListFilters = useAppSelector(getAppliedFilters);
  const filters: ListFilters = useAppSelector(getFilters);
  const isMerchant = useSelector(isMerchantSelector);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset,
    watch,
  } = useForm<any>({ mode: 'onBlur', defaultValues: appliedFilters });

  const previousValues = useRef<ListFilters>(appliedFilters);

  useEffect(() => {
    if (
      history.location.state?.status.length &&
      history.location.state?.transactionType.length
    ) {
      const statusMap = history.location.state.status.map((e: any) => ({
        label: e,
        value: e,
      }));

      const typeMap = history.location.state.transactionType.map((e: any) => ({
        label: e,
        value: e,
      }));

      setValue('status', statusMap);
      setValue('transactionTypes', typeMap);
      dispatch(
        setApplyFilters({ status: statusMap, transactionTypes: typeMap })
      );
    }
  }, [history.location.state, setValue, dispatch]);

  const resetFilters = () => {
    dispatch(resetAllFilters());
    reset(defaultFilters);
  };

  const allValues = watch();

  useFilterEffect(previousValues, allValues, setFilters);

  const handleApplyFilters = (data: ListFilters) => {
    if (data.transactionDate?.from && !data.transactionDate?.to) {
      const updatedTransactionDate = {
        ...data.transactionDate,
        to: data.transactionDate?.from,
      };
      setValue('transactionDate', updatedTransactionDate);
    }
    dispatch(setApplyFilters({}));
    if (toggleDrawer) toggleDrawer();
  };

  const transactionTypes: ItemAttributes[] = getTransactionTypes(t);

  const handleDateChange = (callback: any, dates: any) => {
    const [start, end] = dates;
    if (start) {
      setDateFilter(callback, {
        from: start,
        to: null,
      });
    }

    if (start && end) {
      setDateFilter(callback, {
        from: start,
        to: end,
      });
    }
  };

  const authHasFilter = Boolean(
    filters.id?.length ||
      filters.authorizationCode?.length ||
      filters.cardLastFour?.length ||
      filters.merchants?.length ||
      filters.nameOnCard?.length ||
      filters.merchantExternalReference?.length ||
      filters.stan?.length ||
      filters.status?.length ||
      filters.transactionTypes?.length ||
      filters.transactionDate?.from ||
      (filters.amount &&
        countDecimalPlaces(filters.amount) <= 2 &&
        filters.amount >= 0)
  );

  const filterComponentsList = {
    0: (
      <Input
        label={
          <RequiredFieldComponent
            isRequired={false}
            labelText={t(
              'transactionsManagement.transactions.list.filters.transactionId'
            )}
          />
        }
        labelFontClassName="text-xs font-medium"
        labelColorClassName="text-back"
        error={errors.id}
        inputProps={{
          ...register('id', {
            validate: {
              uuidValidation: (id: string) =>
                id === null ||
                id.length === 0 ||
                /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/i.test(
                  id
                ),
            },
          }),
        }}
      />
    ),
    1: (
      <Input
        label={
          <RequiredFieldComponent
            isRequired={false}
            labelText={t(
              'transactionsManagement.authorizations.list.filters.amount'
            )}
          />
        }
        isBottomError
        type="number"
        min={0.0}
        max={99999.99}
        step={0.01}
        labelFontClassName="text-xs font-medium"
        labelColorClassName="text-back"
        error={errors.amount}
        inputProps={{
          ...register('amount', {
            validate: {
              maxRefundAmountDecimals: (value: any) =>
                value ? countDecimalPlaces(value) <= 2 : true,
              minAmountFilter: (value: any) => value >= 0,
            },
          }),
        }}
      />
    ),
    2: <FilterAuthorizationCode register={register} />,
    3: (
      <InputNumber
        control={control}
        name="cardLastFour"
        label={t(
          'transactionsManagement.transactions.list.filters.cardLastFour'
        )}
      />
    ),
    4: (
      <FilterSelect
        label={t('transactionsManagement.transactions.list.filters.merchant')}
        name="merchants"
        control={control}
        loadOptions={!isMerchant ? merchantList : merchantPortalList}
        isClearable={false}
      />
    ),
    5: <FilterNameOnCard register={register} />,
    6: <FilterMerchantExternalReference register={register} />,
    7: (
      <InputNumber
        control={control}
        name="stan"
        format="#######"
        label={t('transactionsManagement.transactions.list.filters.stan')}
        maxLength={7}
      />
    ),
    8: (
      <FilterSelect
        label={t(
          'transactionsManagement.transactions.list.filters.transactionStatus'
        )}
        name="status"
        control={control}
        loadOptions={authorizationStatusList}
        isClearable={false}
      />
    ),
    9: (
      <FilterSelect
        label={t(
          'transactionsManagement.transactions.list.filters.transactionType'
        )}
        name="transactionTypes"
        control={control}
        options={transactionTypes}
        isClearable={false}
      />
    ),
    10: (
      <DatePickerControl
        control={control}
        label={t(
          'transactionsManagement.transactions.list.filters.transactionDate'
        )}
        handleChange={handleDateChange}
        datePickerType={DatePickerType.STANDARD_DATE_SELECT_ONE_MONTH}
        popoverWidth={260}
        footer={<></>}
        placementPosition="bottom-end"
        name="transactionDate"
        maxDate={new Date()}
        title={t(
          'transactionsManagement.transactions.list.filters.transactionDate'
        )}
      />
    ),
  };

  return (
    <FilterObjectWrapper
      filterFields={filterComponentsList}
      onApply={handleSubmit(handleApplyFilters)}
      onClear={resetFilters}
      isDisabledClear={!authHasFilter}
      isDisabledApply={!authHasFilter}
    />
  );
};
