import { PageHeader } from 'components/PageHeader';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  getCreatePaymentLoadingComputed,
  getPaymentStep,
} from '../../selectors';
import { RectLoader } from 'components/RectLoader';
import { StepWizardComponent } from './StepWizardComponent';
import { useForm } from 'react-hook-form';
import { TransactionsDetails } from './TransactionsDetails';
import { useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import { PaymentMethodDetails } from './PaymentMethodDetails';
import { BillingAddressDetails } from './BillingAddressDetails';
import { SummeryPaymentDetails } from './SummeryPaymentDetails';
import { createParamsDefault } from '../../utils';

export const CreatePayment = () => {
  const { t } = useTranslation();
  const isCreatePaymentLoading = useSelector(getCreatePaymentLoadingComputed);
  const paymentStep = useSelector(getPaymentStep);
  const {
    register,
    handleSubmit,
    formState,
    setError,
    setValue,
    control,
    watch,
    reset,
  } = useForm({
    mode: 'onBlur',
    defaultValues: createParamsDefault,
  });

  const [currentErrors, setCurrentErrors] = useState<any>({});

  useEffect(() => {
    if (!isEqual(formState.errors, currentErrors)) {
      setCurrentErrors({ ...formState.errors });
    }
  }, [formState, currentErrors]);

  return (
    <div className="relative flex flex-col h-full bg-gray-200">
      <div className="flex justify-around items-center">
        <PageHeader title={t('virtualTerminal.createDetails.createPayment')} />
      </div>
      <div className="grid h-full p-3 pt-19 items-start justify-center overflow-auto custom-scrollbar">
        <div className="grid h-auto bg-white border rounded-md box-shadow-dark 3xl:w-279 4xl:w-282 5xl:w-288">
          {isCreatePaymentLoading ? (
            <div className="grid items-center justify-center justify-items-center m-4 p-3 w-full">
              <div className="w-full">
                <RectLoader width={150} height={18} />
              </div>
            </div>
          ) : (
            <>
              <div className="flex items-center justify-center py-10">
                <StepWizardComponent />
              </div>
              {paymentStep === 1 && (
                <TransactionsDetails
                  control={control}
                  reset={reset}
                  errors={currentErrors}
                  register={register}
                  handleSubmit={handleSubmit}
                  watch={watch}
                  setValue={setValue}
                />
              )}
              {paymentStep === 2 && (
                <PaymentMethodDetails
                  reset={reset}
                  errors={currentErrors}
                  register={register}
                  watch={watch}
                  handleSubmit={handleSubmit}
                  setError={setError}
                  setValue={setValue}
                />
              )}
              {paymentStep === 3 && (
                <BillingAddressDetails
                  reset={reset}
                  control={control}
                  errors={currentErrors}
                  register={register}
                  watch={watch}
                  handleSubmit={handleSubmit}
                />
              )}
              {paymentStep === 4 && (
                <SummeryPaymentDetails
                  reset={reset}
                  handleSubmit={handleSubmit}
                  watch={watch}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
