import {
  CardType,
  MatchType,
  MatchTypeLabel,
  TransactionStatus,
  TransactionType,
} from 'entities/transaction-management/transactions';
import { DateRange } from 'types/dateRange';
import { ItemAttributes } from 'components/itemAttributeType';

export const matchTypes = [
  MatchType.PENDING,
  MatchType.SUCCESS,
  MatchType.DUP,
  MatchType.NEQSTAN,
  MatchType.NEQAMT,
  MatchType.NEQNWID,
  MatchType.NOAUTH,
  MatchType.NOSTL,
].map((item) => ({
  value: item,
  label: item,
}));

export const matchTypesItems: ItemAttributes[] = [
  { label: MatchTypeLabel.SUCCESS, value: MatchType.SUCCESS },
  { label: MatchTypeLabel.PENDING, value: MatchType.PENDING },
  { label: MatchTypeLabel.DUPLICATE, value: MatchType.DUP },
  { label: MatchTypeLabel.NEQSTAN, value: MatchType.NEQSTAN },
  { label: MatchTypeLabel.NEQAMT, value: MatchType.NEQAMT },
  { label: MatchTypeLabel.NEQNWID, value: MatchType.NEQNWID },
  { label: MatchTypeLabel.NOAUTH, value: MatchType.NOAUTH },
  { label: MatchTypeLabel.NOSTL, value: MatchType.NOSTL },
];

export type ItemFilters = {
  merchants: ItemAttributes[];
  transactionTypes: ItemAttributes[];
  cardTypes: ItemAttributes[];
  matchTypes: ItemAttributes[];
  transactionStatus: ItemAttributes[];
};

export type MiscFilters = {
  cardLastFour: string;
  authorizationCode: string;
  settlementAmount: number | null;
  referenceNumber: string;
  stan: string;
  id: string;
  settlementDate: DateRange;
  transactionDate: DateRange;
};

export type ListFilters = ItemFilters & MiscFilters;

const itemFilters: ItemFilters = {
  merchants: [],
  transactionTypes: [],
  cardTypes: [],
  matchTypes: [],
  transactionStatus: [],
};

export const miscFilters: MiscFilters = {
  cardLastFour: '',
  authorizationCode: '',
  settlementAmount: null,
  referenceNumber: '',
  stan: '',
  id: '',
  settlementDate: {
    from: null,
    to: null,
  },
  transactionDate: {
    from: null,
    to: null,
  },
};

export const defaultFilters: ListFilters = {
  ...itemFilters,
  ...miscFilters,
};

export const transactionDetails = {
  hierarchyId: '',
  dbaName: '',
  terminalId: '',
  locationId: '',
  storeNumber: '',
  merchantId: '',
  cardBrand: '',
  country: '',
  zip: '',
  cardNumber: '',
  state: '',
  city: '',
  addressLineOne: '',
  addressLineTwo: '',
  expiryDate: '',
  settlementDate: '',
  batchNumber: '',
  batchDate: '',
  authorizationCode: '',
  traceId: '',
  arnNumber: '',
  rrnNumber: '',
  transactionDate: '',
  settlementAmount: 0,
  settlementCurrencyCode: '',
  billingCurrencyAmount: 0,
  billingCurrencyCode: '',
  currencyConversionRate: 0,
  transactionStatus: '',
  transactionType: null,
  transactionMode: '',
  transactionSource: '',
  transactionTime: '',
  matchType: '',
  entryMode: '',
  pcTerminalCapability: '',
  posEntryMode: '',
  partialAuthIndicator: '',
  user: '',
};

const filterValues = (items: ItemAttributes[]) =>
  items.map((item) => item.value);

export const listRequestPayload = (
  options: ListFilters,
  updateTime: (value: any) => string | undefined
) => ({
  filters: {
    merchants:
      options.merchants.length > 0 ? filterValues(options.merchants) : null,
    transactionType:
      options.transactionTypes.length > 0
        ? filterValues(options.transactionTypes)
        : null,
    transactionDate: options.transactionDate.from
      ? {
          from: updateTime(options.transactionDate.from),
          to: updateTime(
            options.transactionDate?.to
              ? options.transactionDate.to
              : options.transactionDate.from
          ),
        }
      : null,
    settlementDate: options.settlementDate.from
      ? {
          from: updateTime(options.settlementDate.from),
          to: updateTime(
            options.settlementDate?.to
              ? options.settlementDate.to
              : options.settlementDate.from
          ),
        }
      : null,
    cardNumberPan: options.cardLastFour || null,
    id: options.id || null,
    authorizationCode: options.authorizationCode || null,
    settlementAmount: options.settlementAmount || null,
    referenceNumber: options.referenceNumber || null,
    stan: options.stan || null,
    matchType:
      options.matchTypes.length > 0 ? filterValues(options.matchTypes) : null,
    transactionStatus:
      options.transactionStatus.length > 0
        ? filterValues(options.transactionStatus)
        : null,
    cardType:
      options.cardTypes.length > 0 ? filterValues(options.cardTypes) : null,
  },
});

interface MappedDataProps {
  authorizationCode: string;
  cardNumber: string;
  id: string;
  cardBrand: CardType;
  matchType: string;
  merchantName: string;
  referenceNumber: string;
  settlementCurrencyCode: string;
  settlementDate: string;
  subMerchantId: string;
  terminalId: string;
  transactionAmount: number;
  transactionDate: string;
  transactionStatus: string;
  transactionTime: {
    hour: number;
    minute: number;
    nano: number;
    second: number;
  };
  transactionType: TransactionType;
}

export const mappedData = (data: any) => {
  return data.map((item: MappedDataProps) => ({
    id: item.id,
    settlementDate: item.settlementDate,
    cardNumber: item.cardNumber,
    terminalId: item.terminalId,
    authorizationCode: item.authorizationCode,
    referenceNumber: item.referenceNumber,
    transactionDate: item.transactionDate,
    transactionAmount: item.transactionAmount,
    settlementCurrencyCode: item.settlementCurrencyCode,
    transactionStatus: item.transactionStatus,
    transactionType: item.transactionType,
    transactionTime: item.transactionTime,
    subMerchantId: item.subMerchantId,
    cardBrand: item.cardBrand,
    subMerchantName: item.merchantName,
    matchType: item.matchType,
  }));
};

interface TransactionDetailsResponseProps {
  arn: string;
  authorizationCode: string;
  batchDate: string;
  batchNumber: string;
  bdaName: string;
  billingAmount: number;
  billingCurrencyCode: string;
  cardBrand: CardType;
  currencyConversionRate: number;
  entryMode: string;
  hierarchyId: string;
  locationId: string;
  maskedCardNumber: string;
  matchType: string;
  partialAuthIndicator: string;
  pcTerminalCapability: string;
  posEntryMode: string;
  rrn: string;
  settlementAmount: number;
  settlementCurrencyCode: string;
  settlementDate: string;
  stan: string;
  storeNumber: string;
  subMerchantId: string;
  terminalId: string;
  transactionDate: string;
  transactionMode: string;
  transactionSource: string;
  orderNumber: string;
  transactionStatus: TransactionStatus;
  transactionTime: string;
  transactionType: TransactionType;
}

export const mappedAuthDetails = ({
  data,
}: {
  data: TransactionDetailsResponseProps;
}) => {
  return {
    hierarchyId: data.hierarchyId,
    dbaName: data.bdaName,
    terminalId: data.terminalId,
    locationId: data.locationId,
    storeNumber: data.storeNumber,
    merchantId: data.subMerchantId,
    cardBrand: data.cardBrand,
    country: 'country',
    zip: 'zip',
    cardNumber: data.maskedCardNumber,
    state: 'state',
    city: 'city',
    addressLineOne: 'address 1',
    addressLineTwo: 'address 2',
    expiryDate: '2020',
    settlementDate: data.settlementDate,
    batchNumber: data.batchNumber,
    batchDate: data.batchDate,
    authorizationCode: data.authorizationCode,
    traceId: data.orderNumber,
    arnNumber: data.arn,
    rrnNumber: data.rrn,
    transactionDate: data.transactionDate,
    settlementAmount: data.settlementAmount,
    settlementCurrencyCode: data.settlementCurrencyCode,
    billingCurrencyAmount: data.billingAmount,
    billingCurrencyCode: data.billingCurrencyCode,
    currencyConversionRate: data.currencyConversionRate,
    transactionStatus: data.transactionStatus,
    transactionType: data.transactionType,
    transactionMode: data.transactionMode,
    transactionSource: data.transactionSource,
    transactionTime: data.transactionTime,
    matchType: data.matchType,
    entryMode: data.entryMode,
    pcTerminalCapability: data.pcTerminalCapability,
    posEntryMode: data.posEntryMode,
    partialAuthIndicator: data.partialAuthIndicator,
  };
};

export const getTransactionStatuses = (t) =>
  [TransactionStatus.PROCESSED, TransactionStatus.REJECTED].map((item) => ({
    value: item,
    label: t(`transactionsManagement.transactions.status.${item}`),
  }));

export const getTransactionTypes = (t) =>
  [
    TransactionType.ACCOUNT_VERIFY_REQUEST,
    TransactionType.AMERICAN_EXPRESS_AUTH_REQUEST,
    TransactionType.CARTE_BLANCHE_AUTH_REQUEST,
    TransactionType.CASH_ADVANCE,
    TransactionType.CASH_ADVANCE_AUTH_REQUEST,
    TransactionType.CONDITIONAL_DEPOSIT_CASH_ADVANCE,
    TransactionType.CONDITIONAL_DEPOSIT_SALE,
    TransactionType.CREDIT,
    TransactionType.DINERS_CLUB_AUTH_REQUEST,
    TransactionType.JBS_AUTH_REQUEST,
    TransactionType.PRIVATE_LABEL_AUTH_REQUEST,
    TransactionType.REGULAR_VOID,
    TransactionType.SALE,
    TransactionType.SALE_AUTH_REQUEST,
    TransactionType.VOID_REVERSAL,
    TransactionType.VOID_UNDELIVERED,
  ].map((item) => ({
    value: item,
    label: t(`transactionsManagement.transactions.transactionType.${item}`),
  }));
