import { useMemo } from 'react';
import { CellLight, ColumnProps } from 'components/table/Table';
import { LoggingDetails } from '../utils';

import { getUserLoggingDescriptions } from './utils';
import { LoggingBadge } from './LoggingBadge';
import { useSelector } from 'react-redux';
import { dateTimeFormat, dateTimeZone } from 'domain/account/selectors';
import {
  dateTimeFormatUtil,
  timeFormatUtil,
} from 'components/dateTimeFormatUtil';

export const useColumns = () => {
  const dateFormat = useSelector(dateTimeFormat);
  const dateZone = useSelector(dateTimeZone);
  const timeFormat = (value: string) =>
    dateTimeFormatUtil(value, dateFormat + timeFormatUtil, dateZone);

  return useMemo(
    (): ColumnProps[] => [
      {
        id: 'loggingTime',
        value: (item: LoggingDetails) => (
          <CellLight>{timeFormat(item.loggingTime)}</CellLight>
        ),
        label: 'Logging Time',
        width: 250,
      },
      {
        id: 'logType',
        value: (item: LoggingDetails) => (
          <CellLight>
            <LoggingBadge logg={item.loggType} />
          </CellLight>
        ),
        label: 'Log Type',
        width: 250,
      },
      {
        id: 'loggingCase',
        value: (item: LoggingDetails) => (
          <CellLight>{getUserLoggingDescriptions(item.loggType)}</CellLight>
        ),
        label: 'Logging Description',
        width: 250,
      },
    ],
    []
  );
};
