import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store/store';

import { storeName } from './virtualTerminalPaymentSlice';

export const getRoot = (state: RootState) => state[storeName];

export const getCreatePaymentLoadingComputed = createSelector(
  [getRoot],
  ({ createPaymentLoading }) => createPaymentLoading
);
export const getPaymentStep = createSelector(
  [getRoot],
  ({ paymentStep }) => paymentStep
);

export const isCardBrandLoadingSelect = createSelector(
  [getRoot],
  ({ isCardBrandLoading }) => isCardBrandLoading
);

export const getModalOpen = createSelector(
  [getRoot],
  ({ isModalOpen }) => isModalOpen
);

export const getErrorMessage = createSelector(
  [getRoot],
  ({ errorMessage }) => errorMessage
);
